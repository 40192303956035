<ion-content>
    <ion-grid class="content">
      <ion-row class="ion-padding-horizontal ion-justify-content-between">
          <h2 class="header-title">Payment Information</h2>
          <ion-button fill="clear" shape="round" class="close-btn" (click)="dismiss()">
            <ion-icon slot="icon-only" name="close"></ion-icon>
          </ion-button>
        </ion-row>
        <ion-row>
        <ion-col class="ion-padding" size="12">
          <ion-card class="info-card ion-no-margin">

            <ion-card-subtitle>
                <span><ion-icon class="info-icon v-middle" color="primary" name="information-circle-outline"></ion-icon></span>
                {{order?.paymentMethod?.metaData?.Instruction}}
              </ion-card-subtitle>
          </ion-card>
        </ion-col>
      </ion-row>
      <!-- <ion-row>
        <ion-col class="ion-padding">
          <ion-text class="label-heading"> Wema Bank </ion-text>
        </ion-col>
      </ion-row> -->
      <ion-row
        class="
          ion-padding-horizontal ion-padding-bottom ion-align-items-center ion-justify-content-between
        "
      >
        <div>
          <ion-text class="label"> Account number </ion-text>
        </div>
        <div>
          <ion-text class="label-heading">
            {{order?.paymentMethod?.metaData?.AccountNumber}}&nbsp;<ion-icon
              name="copy-outline"
              (click)="copyText(order?.paymentMethod?.metaData?.AccountNumber || '')"
            ></ion-icon>
          </ion-text>
        </div>
      </ion-row>
      <ion-row
        class="
          ion-padding-horizontal ion-align-items-center
          ion-padding-bottom
          ion-justify-content-between
        "
      >
        <div>
          <ion-text class="label"> Account name </ion-text>
        </div>
        <div>
          <ion-text class="label-heading" color="primary">
            {{order?.paymentMethod?.metaData?.AccountName}}
          </ion-text>
        </div>
      </ion-row>
      <ion-row
        class="
          ion-padding-horizontal ion-align-items-center ion-padding-bottom ion-justify-content-between
        "
      >
        <div>
          <ion-text class="label">Bank </ion-text>
        </div>
        <div>
          <ion-text class="label-heading">{{order?.paymentMethod?.metaData?.Bank}}
          </ion-text>
        </div>
      </ion-row>

      <ion-row
        class="
          ion-padding-horizontal ion-padding-bottom ion-align-items-center ion-justify-content-between
        "
      >
        <div>
            <ion-text class="label">Transaction Reference </ion-text>
        </div>
        <ion-button (click)="copyText(order?.paymentMethod?.reference ?? '')" class="btn-copy" fill="clear" size="large">{{order?.paymentMethod?.reference}}
            <ion-icon name="copy-outline" slot="end" color="primary"></ion-icon>
        </ion-button>
      </ion-row>

      <ion-row class="ion-padding-horizontal ion-padding-top">
        <ion-col class="ion-text-center">
            <ion-label>
                <ion-card-subtitle><sub>Total Amount</sub></ion-card-subtitle>
            </ion-label>

            <div style="display: inline-block; position: relative; padding-top: 0.3rem;">
                <span style="display: inline-block;vertical-align: top; padding-top: 6px;">&#8358;</span>
                <h3 style="display: inline-block; margin-top: 0; font-size: 30px;">
                {{ amount | number: '1.2-2' }}
            </h3>
            </div>
        </ion-col>
    </ion-row>
    </ion-grid>
</ion-content>

<ion-footer class="ion-no-border">
    <ion-button
    (click)="submit()"
    expand="full"
    fill="outline"
    shape="round"
    size="large"
    style="--border-width: 1px;"
    class="pay-btn ion-text-capitalize">
    I have made the payment</ion-button>
</ion-footer>
