import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../Shared/Services/Api.service';
import {
  ApplyDiscount,
  Checkout,
  Discount,
} from '../checkout/models/checkout.model';
import {
  CompletePayment,
  Order,
  OrderChallenge,
  orderQuery,
} from '../stores/models/orders.model';
import { OrderStatusEnum } from '../stores/models/orders.enum';
import {
  AddRating,
  AddReview,
  FeedBackTypeEnum,
  OrderRating,
  Rating,
  RatingQuery,
  ReviewTypeEnum,
  ReviewTypes,
} from '../orders/models/rating.model';
import { ConfigService } from '../auth/services/config.service';
import {
  DeliveryFeeRequest,
  DeliveryFees,
} from '../stores/models/stores.model';
// import {
//   Firestore,
//   doc,
//   setDoc,
//   updateDoc,
//   getDoc,
//   onSnapshot,
// } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class OrderService extends ApiService {
  baseUrl = `${environment.orderUrl}`;
  private _pendingOrderReviewNotify = new BehaviorSubject<boolean>(false);
  pendingOrderReviewNotify$ = this._pendingOrderReviewNotify.asObservable();
  private _currentOrder = new BehaviorSubject<Order | null>(null);
  private _feedBackTypes = new BehaviorSubject<ReviewTypes[]>([]);
  currentOrder$ = this._currentOrder.asObservable();
  constructor(
    httpClient: HttpClient,
    private config: ConfigService
  ) // private fireStore: Firestore
  {
    super(httpClient);
  }
  checkout(checkoutModel: Checkout) {
    checkoutModel.isMobile = this.config.isMobile();
    return this.post<Order>(checkoutModel, `${this.baseUrl}/check-out`);
  }

  completePayment(payment: CompletePayment) {
    return this.post<Order>(payment, `${this.baseUrl}/complete-payment`);
  }

  applyDiscount(discount: ApplyDiscount) {
    return this.post<Discount>(discount, `${this.baseUrl}/apply-discount`);
  }

  completeCardPayment(reference: string) {
    return this.GetDataWithFilter<Order>(
      { reference },
      `${this.baseUrl}/complete-card-payment`
    );
  }

  calculateFee(fee: DeliveryFeeRequest) {
    return this.post<DeliveryFees>(
      fee,
      `${this.baseUrl}/calculate-delivery-fee`
    );
  }
  get activeOrderStatuses() {
    return [
      OrderStatusEnum.Processing,
      OrderStatusEnum.Accepted,
      OrderStatusEnum.Shipped,
      OrderStatusEnum.PendingLoanApproval,
      OrderStatusEnum.Approved,
    ];
  }

  // docRef(path: string) {
  //   return doc(this.fireStore, path);
  // }

  // getLocation(orderId: string): Observable<any> {
  //   const dataRef = this.docRef(`nodditShop_orderId/${orderId}`);
  //   return new Observable<any>((observer) => {
  //     onSnapshot(dataRef, (doc: any) => {
  //       observer.next(doc.data());
  //     });
  //   });
  // }

  resendOtp(orderId: string) {
    return this.GetAll<OrderChallenge>(`${this.baseUrl}/${orderId}/resend-OTP`);
  }

  getAllOrders(query: orderQuery) {
    return this.post<Order[]>(query, `${this.baseUrl}`);
  }

  pendingOrderReview() {
    return this.GetAll<Order>(
      `${this.baseUrl}/pending-order-review
    `
    ).pipe(
      tap((res) => {
        this._pendingOrderReviewNotify.next(true);
      })
    );
  }

  getOrderById(orderId: string) {
    return this.GetAll<Order>(`${this.baseUrl}/${orderId}`);
  }

  setCurrentOrder(order: Order | null) {
    this._currentOrder.next(order);
  }

  getFeedBackTypes() {
    let feedbacks = this._feedBackTypes.value;
    if (feedbacks.length) return of(feedbacks);
    return this.fetchFeedBackTypes();
  }

  getReviewsByReviewType(reviewType: ReviewTypeEnum) {
    return this.getFeedBackTypes().pipe(
      map((reviews) => {
        return reviews.find((review) => review.reviewType === reviewType);
      })
    );
  }

  getFeedBackByFeedBack(
    reviewType: ReviewTypeEnum,
    feedBackType: FeedBackTypeEnum
  ) {
    return this.getReviewsByReviewType(reviewType).pipe(
      map((review) => {
        return review?.feedBackTypes.find(
          (feedBack) => feedBack.feedBackType === feedBackType
        )?.feedBacks;
      })
    );
  }

  fetchFeedBackTypes() {
    return this.GetAll<ReviewTypes[]>(
      `${this.baseUrl}/get-feedback-types`
    ).pipe(
      map((res) => {
        this._feedBackTypes.next(res.payload);
        return res.payload;
      })
    );
  }

  addReview(reviews: AddReview, isClientReview = false) {
    return this.post<boolean>(
      reviews,
      `${this.baseUrl}/${isClientReview ? 'add-via-client' : 'add'}`
    );
  }
  addClientReview(reviews: AddReview) {
    return this.post<boolean>(reviews, `${this.baseUrl}/add-via-client`);
  }

  getReviews(query: RatingQuery) {
    return this.post<Rating[]>(query, `${this.baseUrl}/get-reviews`);
  }

  getOrderReviews(orderId: string) {
    return this.Get<OrderRating>(orderId, `${this.baseUrl}/get-order-review`);
  }
}
